/* === Tutors === */
.tutors-container {
    margin: 0 auto !important;
    padding-bottom: 2rem !important;
}

.tutors-group::-webkit-scrollbar {
    display: none;
  }

.tutors-header {
    width: 30%;
}

.tutor-container {
    margin: 2rem 0;
}

.tutors-group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    row-gap: 1rem;
    column-gap: 1rem;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.profile {
    min-width: 240px;
    border: solid 1px rgb(227, 227, 227);
    border-radius: 8px;
}

.profile-img-container {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    height: 180px;
    overflow: hidden;
}

.profile-img {
    border-radius: 8px;
    width: 100%;
}

.profile-data {
    padding: 1rem 1rem 1.25rem 1rem;
}

.profile-name {
    font-size: 20px;
    color: var(--red);
    font-weight: 600;
    margin: 0 0 4 0;
}

.profile-atar {
    font-size: 26px;
    font-weight: 600;
    margin: 0 0 4 0;
}

.profile-dux {
    font-size: 16px;
    font-weight: 600;
    color: #6211E2;
    margin: 0;
}

.profile-stat-group {
    margin-top: 4px;
}

.profile-stat {
    margin: 4 0 0 0;
    font-size: 16px;
    line-height: 1.5rem;
}

.purple {
    color: #6211E2;
    font-weight: 600;
}


/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
@media only screen and (max-width: 478px) {
    /* === Tutors === */

    .tutors-group {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        column-gap: 0.5rem;

        overflow-x: scroll;
    }

    .tutors-header {
        width: 100%;
    }
    .profile {
        min-width: 170px;
        border: solid 1px rgb(227, 227, 227);
        border-radius: 8px;
    }
    
    .profile-img-container {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        height: 120px;
        overflow: hidden;
    }
    
    .profile-img {
        border-radius: 8px;
        width: 100%;
    }
    
    .profile-data {
        padding: 0.5rem 0.5rem 0.75rem 0.5rem;
    }
    
    .profile-name {
        font-size: 14px;
        color: var(--red);
        font-weight: 600;
        margin: 0 0 0 0;
    }
    
    .profile-atar {
        font-size: 20px;
        font-weight: 600;
        margin: 0 0 0 0;
        line-height: 1.5rem;
    }
    
    .profile-dux {
        font-size: 12px;
        font-weight: 600;
        color: #6211E2;
        margin: 0;
        line-height: 1.5rem;
    }
    
    .profile-stat-group {
        margin-top: 0px;
    }
    
    .profile-stat {
        margin: 4 0 0 0;
        font-size: 12px;
        line-height: 1rem;
        display: inline-block;
    }
    
    .purple {
        color: #6211E2;
        font-weight: 600;
    }

}